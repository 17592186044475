<div class="navigation-bar mobile-menu" *ngIf="!globals.hideHeaderFooter">
    <a href="/" class="menu-position navbar-brand float-start">
        <img src="../../../../assets/images/ifa-logo-since-1999.svg" height="48" width="196" alt="Index Fund Advisors, Inc Logo Since 1999" />        
    </a>
<div class="menu-button" (click)="openOffCanvas()">
    <fa-icon [icon]="['fas','bars']"></fa-icon> MENU
</div>

<div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasRight" #offcanvasRight aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        MENU <div class="text-secondary float-end" style="cursor:pointer" (click)="closeOffCanvas()">
            <fa-icon [icon]="['fas','times']"></fa-icon>
        </div>

    </div>
    <div class="offcanvas-body">
        <div class="active-menu" (click)="mobileMenuClick($event)">
            <div routerLink="/">Home</div>
            <div routerLink="/find-an-advisor">Connect with an Advisor</div>
            <div routerLink="/survey">Risk Capacity Survey</div>
            <div routerLink="/services">Wealth Management</div>
            <div routerLink="/retirement-plans">Retirement Plans</div>
            <a href="https://ifainstitutional.com/" target="_blank">Institutional</a>
            <div routerLink="/taxes">Taxes</div>
            
            <div class="hasSub">Financial Calculators <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">CALCULATORS <div class="btn btn-success float-end">
                            <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <div routerLink="/calculator">IFA Index Calculator</div>
                    <div routerLink="/retirement-analyzer">Retirement Calculator</div>
                    <div routerLink="/retirement-plans/target-date-comparison">Target Date Calculator</div>
                    <div routerLink="/calculators/retirement-income">Retirement Income</div>
                    <div routerLink="/college-savings">College Planning</div>
                    <div routerLink="/t-stat-calculator">T-Statistic Calculator</div>
                    <div routerLink="/calculators/401k-savings">401(k) Calculator</div>
                    <div routerLink="/calculators/net-worth">Net Worth</div>
                    <div routerLink="/calculators/net-income">Net Income</div>
                    <div routerLink="/calculators/roth-ira">Roth IRA Conversion</div>
                    <div routerLink="/fee-calculator">Fee Calculator</div>
                    <div routerLink="/calculators/multiple-active-funds-calc">Multi Funds Calculator</div>
                </div>
            </div>
            <a routerLink="/open-account">Open an Account</a>
            <a routerLink="/app">IFA App</a>
            <div routerLink="/search">Search</div>
            <div routerLink="/contact">Contact</div>
            <div routerLink="/login">Login</div>
            <div class="hasSub" >Videos <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">VIDEOS <div class="btn btn-success float-end" (click)="submenu($event,'..')">
                            <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <div routerLink="/videos/">Video Library</div>
                    <span>Documentary Films:</span>
                    <div routerLink="/tune-out-the-noise" class="ps-5">Tune Out the Noise</div>
                    <div routerLink="/indexfundsthemovie" class="ps-5">Index Funds: The Movie</div>
                    <a routerLink="/investingtheevidence" class="ps-5">Investing: The Evidence</a>
                    <span>Interviews &amp; Presentations:</span>
                    <a class="ps-5" href="http://www.davidboothinterviews.com" target="_blank">David Booth</a>
                    <a class="ps-5" href="http://www.markhebnerinterviews.com" target="_blank">Mark Hebner</a>
                    <a class="ps-5" href="http://www.eugenefamainterviews.com" target="_blank">Eugene Fama</a>
                    <a class="ps-5" href="http://www.kennethfrenchinterviews.com" target="_blank">Kenneth French</a>
                    <a class="ps-5" href="http://www.harrymarkowitzinterviews.com" target="_blank">Harry Markowitz</a>
                    <a class="ps-5" href="http://www.gerardoreillyinterviews.com" target="_blank">Gerard O'Reilly</a>
                    <a class="ps-5" href="http://www.westonwellingtoninterviews.com" target="_blank">Weston Wellington</a>
                    <a class="ps-5" href="http://www.scottbosworthinterviews.com" target="_blank">Scott Bosworth</a>
                    <a class="ps-5" href="http://www.apollolupescuinterviews.com" target="_blank">Apollo Lupescu</a>
                </div>
            </div>
            <div routerLink="/articles">Articles</div>
            <div routerLink="/charts">Charts</div>

            <div class="hasSub" >Investor Education <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">Investor Education <div class="btn btn-success float-end" (click)="submenu($event,'..')">
                            <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <div routerLink="/12steps/introduction">Introduction</div>
                    <div routerLink="/12steps/step1">Step 1: Active Investors</div>
                    <div routerLink="/12steps/step2">Step 2: Nobel Laureates</div>
                    <div routerLink="/12steps/step3">Step 3: Stock Pickers</div>
                    <div routerLink="/12steps/step4">Step 4: Time Pickers</div>
                    <div routerLink="/12steps/step5">Step 5: Manager Pickers</div>
                    <div routerLink="/12steps/step6">Step 6: Style Drifters</div>
                    <div routerLink="/12steps/step7">Step 7: Silent Partners</div>
                    <div routerLink="/12steps/step8">Step 8: Riskese</div>
                    <div routerLink="/12steps/step9">Step 9: History</div>
                    <div routerLink="/12steps/step10">Step 10: Risk Capacity</div>
                    <div routerLink="/12steps/step11">Step 11: Risk Exposure</div>
                    <div routerLink="/12steps/step12">Step 12: Invest and Relax</div>
                    <div routerLink="/12steps/appendix">Appendix</div>
                </div>
            </div>

            <div class="hasSub" >Index Portfolios <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">INDEX PORTFOLIOS <div class="btn btn-success float-end" (click)="submenu($event,'..')">
                            <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <div routerLink="/portfolios/100">IFA Index Portfolio 100</div>
                    <div routerLink="/portfolios/95">IFA Index Portfolio 95</div>
                    <div routerLink="/portfolios/90">IFA Index Portfolio 90</div>
                    <div routerLink="/portfolios/85">IFA Index Portfolio 85</div>
                    <div routerLink="/portfolios/80">IFA Index Portfolio 80</div>
                    <div routerLink="/portfolios/75">IFA Index Portfolio 75</div>
                    <div routerLink="/portfolios/70">IFA Index Portfolio 70</div>
                    <div routerLink="/portfolios/65">IFA Index Portfolio 65</div>
                    <div routerLink="/portfolios/60">IFA Index Portfolio 60</div>
                    <div routerLink="/portfolios/55">IFA Index Portfolio 55</div>
                    <div routerLink="/portfolios/50">IFA Index Portfolio 50</div>
                    <div routerLink="/portfolios/45">IFA Index Portfolio 45</div>
                    <div routerLink="/portfolios/40">IFA Index Portfolio 40</div>
                    <div routerLink="/portfolios/35">IFA Index Portfolio 35</div>
                    <div routerLink="/portfolios/30">IFA Index Portfolio 30</div>
                    <div routerLink="/portfolios/25">IFA Index Portfolio 25</div>
                    <div routerLink="/portfolios/20">IFA Index Portfolio 20</div>
                    <div routerLink="/portfolios/15">IFA Index Portfolio 15</div>
                    <div routerLink="/portfolios/10">IFA Index Portfolio 10</div>
                    <div routerLink="/portfolios/5">IFA Index Portfolio 5</div>
                    <div routerLink="/sustainablity">Sustainable Portfolios</div>
                    <div routerLink="/portfolios">Portfolio Overview</div>
                    <div routerLink="/portfolios/risk-and-return">Risk & Return</div>
                    <div routerLink="/portfolios/matching-people-with-portfolios">Matching People with Portfolios</div>
                </div>
            </div>
            <a href="https://www.ifa.com/pdfs/ifa_implementations_form.pdf" target="_blank">Fund Options</a>
            <div class="hasSub" >Resources <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">RESOURCES <div class="btn btn-success float-end" (click)="submenu($event,'..')">
                            <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <div *ngIf="loginDisplay"><a href="/pdfs/ifa_brochure.pdf" target="_blank">IFA Brochure</a></div>
                    <div routerLink="/estate-planning" target="_blank">Estate Planning Attorneys</div>
                    <div routerLink="/books">Index Funds Book</div>
                    <div routerLink="/gallery">IFA Art Gallery</div>
                    <div routerLink="/book-library">Book Library</div>
                    <div routerLink="/academic-papers">Academic Papers</div>
                    <div routerLink="/quotes">Quotes</div>
                    <div *ngIf="loginDisplay"><a href="/pdfs/the-academic-advantage.pdf" rel="nofollow" target="_blank">Academic Advantage</a></div>
                    <div routerLink="/app">Mobile App</div>
                    <div routerLink="/sitemap">Icon Library</div>
                </div>
            </div>
            <!-- <div routerLink="/about">About Us</div> -->
            <div class="hasSub" >About Us <fa-icon [icon]="['fas','chevron-right']"></fa-icon>
                <div class="main-submenu">
                    <div class="main-submenu-header">RESOURCES <div class="btn btn-success float-end" (click)="submenu($event,'..')">
                        <fa-icon [icon]="['fas','chevron-left']"></fa-icon>
                        </div>
                    </div>
                    <a routerLink="/about" >Overview</a>
                    <a routerLink="/about/value-of-ifa" >Value of IFA</a>
                    <a routerLink="/about/advisors" >Wealth Advisors</a>
                    <a routerLink="/about/client-services-specialists" >Client Service Specialists</a>
                    <a routerLink="/about/management" >Management</a>
                    <a routerLink="/taxes/about" >Taxes Department</a>
                    <a routerLink="/about/financial-planning-department" >Financial Planning Department</a>
                    <a routerLink="/about/investment-principles" >Investment Principles</a>
                    <a routerLink="/about/our-fiduciary-role" >Our Fiduciary Role</a>
                    <a routerLink="/fees" >Fees</a>
                    <a routerLink="/careers" >Careers</a>
                </div>
            </div>



            <a href="https://store.ifa.com" target="_blank">Store</a>
            <div routerLink="/galton-board">Galton Board</div>
            <div routerLink="/petition">Petition</div>
            <div routerLink="/disclosures">Back-testing Disclosures</div>
            <div routerLink="/disclosures/index-data">Index Descriptions</div>
            <div routerLink="/glossary">Glossary</div>
            <a href="https://www.ifa.com/pdfs/ifa-form-crs.pdf" target="_blank">Form CRS</a>
            
            <!-- <div routerLink="/careers">Become an Advisor</div> -->
        </div>
    </div>
</div>
</div>

<div class="navigation-bar desktop-menu" *ngIf="!globals.hideHeaderFooter">
    <div class="d-flex">
        
        <a routerLink="/" class="m-logo"><img src="/assets/images/ifa-logo-since-1999.svg" width="204" height="50" alt="Index Fund Advisors Logo"><span style="padding-left:6px;">Home</span></a>  
        
        <a class="m-icon text-nowrap" routerLink="/survey" style="min-width:90px;"><img loading="lazy"  height="35" width="35" src="/assets/images/icons/menu/svg/risk-capacity-survey.svg" alt="Risk Capacity Survey">Risk Capacity<br>Survey</a>
        <a class="m-icon" routerLink="/services" style="word-break: keep-all;"><img loading="lazy"  height="35" width="35" src="/assets/images/icons/menu/svg/wealth-management.svg" alt="Person talking about money icon">Wealth Management</a>
        <a class="m-icon" routerLink="/retirement-plans"><img loading="lazy"  height="35" width="35" src="/assets/images/icons/menu/svg/retirement-plans.svg" alt="Piggy bank icon">Retirement Plans</a>
        <a class="m-icon" href="https://ifainstitutional.com/" target="_blank"><img loading="lazy"  height="35" width="35" src="/assets/images/icons/menu/svg/institutional.svg" alt="Skyscrapers icon">Institutional</a>
        <a class="m-icon" routerLink="/taxes"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/taxes.svg" alt="Tax form icon">Taxes</a>

        <div class="m-icon hasBranch"><img src="/assets/images/icons/menu/svg/financial-calculators.svg" alt="Financial Calculators">Financial <span class="text-nowrap">Calculators <b>▾</b></span>
            <div *ngIf="vIC" class="branch" (click)="branchClick($event)" style="width:408px;left:-161px">                    
                    <a routerLink="/calculator" ><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-index-calculator.svg" class="m-icon" height="35" width="35" alt="IFA Index Calculator">IFA Index Calculator</a>
                    <a routerLink="/retirement-analyzer" ><img loading="lazy" src="/assets/images/icons/menu/svg/retirement-calculator.svg" class="m-icon" height="35" width="35" alt="Retirement Calculator">Retirement Calculator</a>
                    <a routerLink="/retirement-plans/target-date-comparison"><img loading="lazy" src="/assets/images/icons/menu/svg/target-date-calculator.svg" class="m-icon" height="35" width="35" alt="Target Date Calculator">Target Date Calculator</a>
                    <a routerLink="/calculators/retirement-income" ><img loading="lazy" src="/assets/images/icons/menu/svg/net-income-calculator.svg" class="m-icon" height="35" width="35" alt="Retirement Income">Retirement Income</a>
                    <a routerLink="/college-savings" ><img loading="lazy" src="/assets/images/icons/menu/svg/college-planning.svg" class="m-icon" height="35" width="35">College Planning</a>
                    <a routerLink="/t-stat-calculator" fragment="tcalc2" ><img loading="lazy" src="/assets/images/icons/menu/svg/t-statistic-calculator.svg" class="m-icon" height="35" width="35" alt="T-Statistic Calculator">T-Statistic Calculator</a>
                    <a routerLink="/calculators/401k-savings" ><img loading="lazy" src="/assets/images/icons/menu/svg/401k-calculator.svg" class="m-icon" height="35" width="35" alt="401(k) Calculator">401(k) Calculator</a>
                    <a routerLink="/calculators/net-worth" ><img loading="lazy" src="/assets/images/icons/menu/svg/net-worth-calculator.svg" class="m-icon" height="35" width="35" alt="Net Worth">Net Worth</a>
                    <a routerLink="/calculators/net-income" ><img loading="lazy" src="/assets/images/icons/menu/svg/net-income-calculator.svg" class="m-icon" height="35" width="35" alt="Net Income">Net Income</a>
                    <a routerLink="/calculators/roth-ira" ><img loading="lazy" src="/assets/images/icons/menu/svg/roth-ira-conversion.svg" class="m-icon" height="35" width="35" alt="Roth IRA Conversion">Roth IRA Conversion</a>
                    <a routerLink="/fee-calculator" ><img loading="lazy" src="/assets/images/icons/menu/svg/fees-calculator.svg" class="m-icon" height="35" width="35" alt="Fee Calculator">Fee Calculator</a>
                    <a routerLink="/calculators/multiple-active-funds-calc" ><img loading="lazy" src="/assets/images/icons/menu/svg/multi-funds-calculator.svg" class="m-icon" height="35" width="35" alt="Mutiple Funds Calculator">Multi Funds Calculator</a>                
            </div>
        </div>
        
        <a class="m-icon" routerLink="/open-account"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/open-new-account.svg" alt="Briefcase icon">Open an Account</a>
        
        <a [routerLink]="['/find-an-advisor']" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/connect-with-an-advisor.svg" alt="Advisor icon"><span style="white-space:nowrap">Connect with</span> an Advisor</a>

        <a [routerLink]="['/app']" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/ifa-app.svg?v=2025-02-26" alt="app icon">IFA App</a>
        
        <div class="m-top-right">
            <div class="m-cir search"><fa-icon [icon]="['fas','search']"></fa-icon><input id="site_search" style="width:140px;" type="text" (keyup.enter)="searchIFA()" placeholder="Search ifa.com" #searchTerm /></div>
            <div class="m-cir phone-number"> <a href="tel:+1{{globals.phoneTollFree}}"><fa-icon [icon]="['fas','phone-alt']"></fa-icon>{{globals.phoneTollFree}}</a></div>
            <div class="m-cir contact-us"> <a [routerLink]="['contact']"><fa-icon [icon]="['fas','envelope']"></fa-icon>Contact Us</a></div>
            <div class="m-cir login"> <a [routerLink]="['login']"><fa-icon [icon]="['fas','lock']"></fa-icon>Login</a></div>
        </div>
    </div>
    <div class="split"></div>
    <div class="d-flex">
        <div class="m-icon hasBranch"><img loading="lazy"  height="35" width="35" src="/assets/images/icons/menu/svg/videos.svg" alt="Videos"><span class="text-nowrap">Videos <b>▾</b></span>
            <div *ngIf="vIC" class="branch vmenu vmtop" (click)="branchClick($event)">   
                <p style="margin-top: 10px"><a routerLink="/videos" class="d-block videos-anchor" ><img loading="lazy" src="/assets/images/icons/menu/svg/videos.svg" class="m-icon" height="35" width="35" alt="Video Library">Video Library</a></p>
                <hr style="margin-top:-5px">
                <p class="nohand ct videosection">Documentary Films</p>
                    <a routerLink="/tune-out-the-noise" class="vma">
                        <img loading="lazy" src="/assets/images/icons/menu/35x35/tuneoutthenoise.webp" class="m-icon" height="35" width="35" alt="Tune Out the Noise">Tune Out the Noise
                    </a>
                    <a routerLink="/indexfundsthemovie" class="vma">
                        <img loading="lazy" src="/assets/images/icons/menu/35x35/indexfundsthemovie.webp" class="m-icon" height="35" width="35" alt="Index Funds: The Movie">Index Funds: The Movie
                    </a>
                    <a routerLink="/investingtheevidence" class="vma">
                        <img loading="lazy" src="/assets/images/icons/menu/35x35/investingtheevidence.webp" class="m-icon" height="35" width="35" alt="Investing: The Evidence">Investing: The Evidence
                    </a>
                <hr>
                <p class="nohand videosection">Interviews and Presentations</p>
                <a href="http://www.davidboothinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/davidbooth.webp" class="m-icon" height="35" width="35" alt="David Booth">David Booth</a>
                <a href="http://www.markhebnerinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/markhebner.webp" class="m-icon" height="35" width="35" alt="Mark Hebner">Mark Hebner</a>
                <a href="http://www.eugenefamainterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/eugenefama.webp" class="m-icon" height="35" width="35" alt="Eugene Fama">Eugene Fama</a>
                <a href="http://www.kennethfrenchinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/kennethfrench.webp" class="m-icon" height="35" width="35" alt="Kenneth French">Kenneth French</a>
                <a href="http://www.harrymarkowitzinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/harrymarkowitz.webp" class="m-icon" height="35" width="35" alt="Harry Markowitz">Harry Markowitz</a>
                <a href="http://www.gerardoreillyinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/gerardoreilly.webp" class="m-icon" height="35" width="35" alt="Gerard O'Reilly">Gerard O'Reilly</a>
                <a href="http://www.westonwellingtoninterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/westonwellington.webp" class="m-icon" height="35" width="35" alt="Weston Wellington">Weston Wellington</a>
                <a href="http://www.scottbosworthinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/scottbosworth.webp" class="m-icon" height="35" width="35" alt="Scott Bosworth">Scott Bosworth</a>
                <a href="http://www.apollolupescuinterviews.com/" ><img loading="lazy" src="/assets/images/icons/menu/35x35/apollolupescu.webp" class="m-icon" height="35" width="35" alt="Apollo Lupescu">Apollo Lupescu</a>
            </div>
        </div>

        <a class="m-icon"  [routerLink]="['/articles']"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/articles.svg" alt="article icon">Articles</a>

        <a routerLink="charts" class="m-icon" ><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/charts.svg" alt="chart icon">Charts</a>        
        
        <div class="m-icon-double hasBranch"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/12-steps.svg" alt="pie chart icon">Investor Education <b>▾</b>
            <div *ngIf="vIC" class="branch stepBranch" (click)="branchClick($event)" style="width:422px;left:-150px">                   
                <a [routerLink]="['/12steps/introduction']" ><img loading="lazy" src="/assets/images/icons/menu/svg/intro.svg" class="m-icon" height="35" width="35" alt="Introduction">Introduction</a>
                <a [routerLink]="['/12steps/step7']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-7.svg" class="m-icon" height="35" width="35" alt="step seven icon">Silent Partners </a>
                <a [routerLink]="['/12steps/step1']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-1.svg" class="m-icon" height="35" width="35" alt="step one icon">Active Investors </a>
                <a [routerLink]="['/12steps/step8']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-8.svg" class="m-icon" height="35" width="35" alt="step eight icon">Riskese </a>
                <a [routerLink]="['/12steps/step2']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-2.svg" class="m-icon" height="35" width="35" alt="step two icon">Nobel Laureates </a>
                <a [routerLink]="['/12steps/step9']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-9.svg" class="m-icon" height="35" width="35" alt="step nine icon">History </a>
                <a [routerLink]="['/12steps/step3']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-3.svg" class="m-icon" height="35" width="35" alt="step three icon">Stock Pickers </a>
                <a [routerLink]="['/12steps/step10']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-10.svg" class="m-icon" height="35" width="35" alt="step ten icon">Risk Capacity </a>
                <a [routerLink]="['/12steps/step4']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-4.svg" class="m-icon" height="35" width="35" alt="step four icon">Time Pickers </a>
                <a [routerLink]="['/12steps/step11']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-11.svg" class="m-icon" height="35" width="35" alt="step eleven icon">Risk Exposure </a>
                <a [routerLink]="['/12steps/step5']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-5.svg" class="m-icon" height="35" width="35" alt="step five icon">Manager Pickers </a>
                <a [routerLink]="['/12steps/step12']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-12.svg" class="m-icon" height="35" width="35" alt="step twelve icon">Invest and Relax </a>
                <a [routerLink]="['/12steps/step6']" ><img loading="lazy" src="/assets/images/icons/menu/svg/step-6.svg" class="m-icon" height="35" width="35" alt="step six icon">Style Drifters </a>                
                <a [routerLink]="['/12steps/appendix']" ><img loading="lazy" src="/assets/images/icons/menu/svg/appendix.svg" class="m-icon" height="35" width="35" alt="Appendix icon">Appendix </a>
            </div>
        </div>       
        
        <div class="m-icon hasBranch"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/ifa-index-portfolios.svg" alt="pie chart icon"><span class="text-nowrap">Index<br/>Portfolios <b>▾</b></span>
            <div *ngIf="vIC" class="branch p-branch" (click)="branchClick($event)" style="width:418px;left:-150px">   
                <a [routerLink]="['/portfolios/100']" ><img loading="lazy" src="/assets/images/icons/menu/svg/100.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 100">IFA Index<br/> Portfolio 100</a>
                <a [routerLink]="['/portfolios/95']" ><img loading="lazy" src="/assets/images/icons/menu/svg/95.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 95">IFA Index<br/> Portfolio 95</a>
                <a [routerLink]="['/portfolios/90']" ><img loading="lazy" src="/assets/images/icons/menu/svg/90.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 90">IFA Index<br/> Portfolio 90</a>
                <a [routerLink]="['/portfolios/85']" ><img loading="lazy" src="/assets/images/icons/menu/svg/85.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 85">IFA Index<br/> Portfolio 85</a>
                <a [routerLink]="['/portfolios/80']" ><img loading="lazy" src="/assets/images/icons/menu/svg/80.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 80">IFA Index<br/> Portfolio 80</a>
                <a [routerLink]="['/portfolios/75']" ><img loading="lazy" src="/assets/images/icons/menu/svg/75.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 75">IFA Index<br/> Portfolio 75</a>
                <a [routerLink]="['/portfolios/70']" ><img loading="lazy" src="/assets/images/icons/menu/svg/70.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 70">IFA Index<br/> Portfolio 70</a>
                <a [routerLink]="['/portfolios/65']" ><img loading="lazy" src="/assets/images/icons/menu/svg/65.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 65">IFA Index<br/> Portfolio 65</a>
                <a [routerLink]="['/portfolios/60']" ><img loading="lazy" src="/assets/images/icons/menu/svg/60.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 60">IFA Index<br/> Portfolio 60</a>
                <a [routerLink]="['/portfolios/55']" ><img loading="lazy" src="/assets/images/icons/menu/svg/55.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 55">IFA Index<br/> Portfolio 55</a>
                <a [routerLink]="['/portfolios/50']" ><img loading="lazy" src="/assets/images/icons/menu/svg/50.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 50">IFA Index<br/> Portfolio 50</a>
                <a [routerLink]="['/portfolios/45']" ><img loading="lazy" src="/assets/images/icons/menu/svg/45.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 45">IFA Index<br/> Portfolio 45</a>
                <a [routerLink]="['/portfolios/40']" ><img loading="lazy" src="/assets/images/icons/menu/svg/40.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 40">IFA Index<br/> Portfolio 40</a>
                <a [routerLink]="['/portfolios/35']" ><img loading="lazy" src="/assets/images/icons/menu/svg/35.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 35">IFA Index<br/> Portfolio 35</a>
                <a [routerLink]="['/portfolios/30']" ><img loading="lazy" src="/assets/images/icons/menu/svg/30.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 30">IFA Index<br/> Portfolio 30</a>
                <a [routerLink]="['/portfolios/25']" ><img loading="lazy" src="/assets/images/icons/menu/svg/25.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 25">IFA Index<br/> Portfolio 25</a>
                <a [routerLink]="['/portfolios/20']" ><img loading="lazy" src="/assets/images/icons/menu/svg/20.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 20">IFA Index<br/> Portfolio 20</a>
                <a [routerLink]="['/portfolios/15']" ><img loading="lazy" src="/assets/images/icons/menu/svg/15.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 15">IFA Index<br/> Portfolio 15</a>
                <a [routerLink]="['/portfolios/10']" ><img loading="lazy" src="/assets/images/icons/menu/svg/10.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 10">IFA Index<br/> Portfolio 10</a>
                <a [routerLink]="['/portfolios/5']" ><img loading="lazy" src="/assets/images/icons/menu/svg/5.svg" class="m-icon-centered" height="35" width="35" alt="IFA Index Portfolio 5">IFA Index<br/> Portfolio 5</a>            
                <a [routerLink]="['/sustainability']"><img loading="lazy" src="/assets/images/icons/menu/svg/sustainable-portfolios.svg" class="m-icon-centered" height="35" width="35" alt="Sustainable icon">Sustainable<br>Portfolios</a>
                <a [routerLink]="['/portfolios']"><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-index-portfolios.svg" class="m-icon-centered" height="35" width="35" alt="Sustainable icon">Portfolio<br>Overview</a>
                <a [routerLink]="['/portfolios/risk-and-return']"><img loading="lazy" src="/assets/images/icons/menu/svg/risk-and-return.svg" class="m-icon-centered" height="35" width="35" alt="Risk & Return icon">Risk &<br>Return</a>
                <a [routerLink]="['/portfolios/matching-people-with-portfolios']"><img loading="lazy" src="/assets/images/icons/menu/svg/matching-people-with-portfolios.svg" class="m-icon-centered" height="35" width="35" alt="Matching People with Portfolios icon">Matching People<br> with Portfolios</a>            
            </div>
        </div>

        <a href="https://www.ifa.com/pdfs/ifa_implementations_form.pdf" target="_blank" class="m-icon-double"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/fund-options.svg" alt="Fund Options icon" >Fund<br>Options</a>       

        <div class="m-icon hasBranch"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/resources.svg" alt="Resources"><span class="text-nowrap">Resources<b>▾</b></span>        
            <div *ngIf="vIC" class="branch" (click)="branchClick($event)" style="width:418px;left:-150px">  
                <a href="https://www.ifa.com/pdfs/ifa_brochure.pdf" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-brochure.svg" class="m-icon" height="35" width="35" alt="Brochure">IFA Brochure</a>
                <a routerLink="/estate-planning"><img loading="lazy" src="/assets/images/icons/menu/svg/estate-planning-attorneys.svg" class="m-icon" height="35" width="35" alt="Estate Planning">Estate Planning Attorneys</a>                
                <a routerLink="/books" ><img loading="lazy" src="/assets/images/icons/menu/svg/index-funds-book.svg" class="m-icon" height="35" width="35" alt="Book">Index Funds Book</a>
                <a routerLink="/gallery" ><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-art-gallery.svg" class="m-icon" height="35" width="35" alt="Gallery">IFA Art Gallery</a>
                <a routerLink="/book-library" ><img loading="lazy" src="/assets/images/icons/menu/svg/book-library.svg" class="m-icon" height="35" width="35" alt="Book Library">Book Library</a>
                <a routerLink="/academic-papers" ><img loading="lazy" src="/assets/images/icons/menu/svg/academic-papers.svg" class="m-icon" height="35" width="35" alt="Academic Papers">Academic Papers</a>
                <a routerLink="/quotes" ><img src="/assets/images/icons/menu/svg/quotes.svg" class="m-icon" height="35" width="35" alt="Quotes">Quotes</a>                
                <a href="https://www.ifa.com/pdfs/the-academic-advantage.pdf" target="_blank" ><img loading="lazy" src="/assets/images/icons/menu/svg/academic-advantage.svg" class="m-icon" height="35" width="35" alt="Academic Advantage">Academic Advantage</a>
                <a routerLink="/app" ><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-app.svg" class="m-icon" height="35" width="35" alt="Mobile App">Mobile App</a>
                <a routerLink="/sitemap" ><img loading="lazy" src="/assets/images/icons/menu/svg/icon-library.svg" class="m-icon" height="35" width="35" alt="Icon Library">Icon Library</a>
            </div>
        </div>

        <div class="m-icon hasBranch"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/about-us.svg" alt="About Us"><span class="text-nowrap">About Us <b>▾</b></span>
            <div *ngIf="vIC" class="branch aboutBranch" (click)="branchClick($event)" style="width:500px;left:-150px">   
                <a routerLink="/about" ><img loading="lazy" src="/assets/images/icons/menu/svg/overview.svg" class="m-icon" height="35" width="35" alt="Overview">Overview</a>
                <a routerLink="/about/value-of-ifa" ><img loading="lazy" src="/assets/images/icons/menu/svg/value-of-ifa.svg" class="m-icon" height="35" width="35" alt="Value of IFA">Value of IFA</a>
                <a routerLink="/about/advisors" ><img loading="lazy" src="/assets/images/icons/menu/svg/wealth-advisors.svg" class="m-icon" height="35" width="35" alt="Wealth Advisors">Wealth Advisors</a>
                <a routerLink="/about/client-services-specialists" ><img loading="lazy" src="/assets/images/icons/menu/svg/client-service-specialists.svg" class="m-icon" height="35" width="35" alt="Client Service Specialists">Client Service Specialists</a>
                <a routerLink="/about/management" ><img loading="lazy" src="/assets/images/icons/menu/svg/management.svg" class="m-icon" height="35" width="35" alt="Management">Management</a>
                <a routerLink="/taxes/about" ><img loading="lazy" src="/assets/images/icons/menu/svg/taxes-team.svg" class="m-icon" height="35" width="35" alt="Taxes Department">Taxes Department</a>
                <a routerLink="/about/financial-planning-department" ><img loading="lazy" src="/assets/images/icons/menu/svg/financial-planning-team.svg" class="m-icon" height="35" width="35" alt="Financial Planning Department">Financial Planning Department</a>
                <a routerLink="/about/investment-principles" ><img loading="lazy" src="/assets/images/icons/menu/svg/investment-principles.svg" class="m-icon" height="35" width="35" alt="Investment Principles">Investment Principles</a>
                <a routerLink="/about/our-fiduciary-role" ><img loading="lazy" src="/assets/images/icons/menu/svg/our-fiduciary-role.svg" class="m-icon" height="35" width="35" alt="Our Fiduciary Role">Our Fiduciary Role</a>
                <a routerLink="/fees" ><img loading="lazy" src="/assets/images/icons/menu/svg/fees.svg" class="m-icon" height="35" width="35" alt="Fees">Fees</a>
                <a routerLink="/careers" ><img loading="lazy" src="/assets/images/icons/menu/svg/careers.svg" class="m-icon" height="35" width="35" alt="Careers">Careers</a>
            </div>
        </div>

        <a routerLink="/store" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/store.svg" alt="shopping bag icon">Store</a>
        <a routerLink="/galton-board" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/galton-board.svg" alt="galton board icon">Galton<br>Board</a>        
        <a routerLink="/petition" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/petition.svg" alt="clipboard icon">Petition</a>        
        <a [routerLink]="['/disclosures']" class="m-icon-double"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/disclosures.svg" alt="Disclosures icon">Back-testing Disclosures</a>
        <a [routerLink]="['/disclosures/index-data/']" class="m-icon-double"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/index-descriptions.svg" alt="info icon">Index Descriptions</a>
        <a class="m-icon" routerLink="/glossary"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/glossary.svg" alt="glossary icon">Glossary</a>
        <a href="https://www.ifa.com/pdfs/ifa-form-crs.pdf" target="_blank" class="m-icon"><img loading="lazy" height="35" width="35" src="/assets/images/icons/menu/svg/form-crs.svg" alt="form-crs icon">Form CRS</a>
        
    </div>   
</div>
