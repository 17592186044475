import {
    CommonModule,
    isPlatformBrowser
} from '@angular/common';
import {
    Component,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-get-the-app-toast',
    templateUrl: './get-the-app-toast.component.html',
    styleUrl: './get-the-app-toast.component.scss',
    imports:[FontAwesomeModule, CommonModule]
})
export class GetTheAppToastComponent {

    closed = true;
    link = "";

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
      this.link = this.getAppStoreLink();
        if (this.link!="") {
            var cookie = this.getCookie("mobileAppToastClosed");
            var cookieApp = this.getCookie("NET_TOKEN");
            if ((cookie != "" && cookie != "UNKNOWN") || (cookieApp != "" && cookieApp != "UNKNOWN")) {
               // setTimeout(()=>{
                    this.closed = true;
               // },8000)
            } else {
                 setTimeout(()=>{
                     this.closed = false;
                 },4000);            
            }
        }
    }


    isAndroid(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            //safe to use navigator (ssr)
            return /Android/i.test(navigator.userAgent);
        }
        return false;
    }

    isiOS(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            //safe to use navigator (ssr)
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        }
        return false;
    }

    getAppStoreLink(): string {
        if (isPlatformBrowser(this.platformId)) {
            // Safe to use navigator here  
            if (this.isAndroid()) {
                return 'https://play.google.com/store/apps/details?id=com.indexfundsadvisorsinc.ifa'; // Replace with your app's Play Store link  
            } else if (this.isiOS()) {
                return 'https://itunes.apple.com/us/app/index-fund-advisors/id914924554'; // Replace with your app's App Store link  
            }
        }
        return ''; // Return empty string if not a mobile device  
    }

    close() {
        this.closed = true;
        this.setCookie("mobileAppToastClosed", "true", 365);
    }

    public getCookie(name: string) {
        if (isPlatformBrowser(this.platformId)) {
            // Safe to use document here  
            let ca: Array < string > = document.cookie.split(';');
            //console.log(document.cookie);
            let caLen: number = ca.length;
            let cookieName = `${name}=`;
            let c: string;

            for (let i: number = 0; i < caLen; i += 1) {
                c = ca[i].replace(/^\s+/g, '');
                if (c.indexOf(cookieName) == 0) {
                    return c.substring(cookieName.length, c.length);
                }
            }
        }

        return '';
    }
    // Set a cookie  
    setCookie(name: string, value: string, days: number = 365) {
        if (isPlatformBrowser(this.platformId)) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${name}=${value};${expires};path=/`;
            console.log('Cookie set:', name, value);
        } else {
            console.log('Not running in the browser, cookie not set');
        }
    }


}