import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var gtag: Function

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { }

public event(eventName: string, params: {}) {
  gtag('event', eventName, params);
}

public init(key: any) {
  this.listenForRouteChanges(key);

  if (isPlatformBrowser(this.platformId)) {  
    // Safe to use document here  
    try {

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + key;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + key + `', {'send_page_view': false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
}

private listenForRouteChanges(key: any) {
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      gtag('config', key, {
        'page_path': event.urlAfterRedirects,
      });
      //console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
      //console.log('Property ID',key);
    }
  });
}

}
