import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
import {
    HeadService
} from 'src/app/services/head.service';
import {
    Meta
} from '@angular/platform-browser';
import {
    CommonModule,
    isPlatformBrowser
} from '@angular/common';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    imports: [CommonModule],
    host: {ngSkipHydration: 'true'}
})
export class NotFoundComponent implements OnDestroy {

  isBrowser = false;
    constructor(private head: HeadService, private meta: Meta,  @Inject(PLATFORM_ID) private platformId: string,) {
       
       
    }
    ngOnInit() {
      this.isBrowser = isPlatformBrowser(this.platformId);
      if(this.isBrowser){
        this.head.setTitle("Page Not Found")
        this.head.setDescription("The page you've requested is not avaiable.")
        this.head.setKeywords("Page Not Found, 404, Try Another Page")

        this.meta.addTag({
          name: "robots",
          content: "noindex"
      }); //This is how angular.io does it, but we have to remove it once navigation resumes on a good page.
      }
    }
    ngOnDestroy(): void {
      if(this.isBrowser){
        this.meta.removeTag("name='robots'");
      }
       
    }



}