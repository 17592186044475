
<div class="navigation-bar" *ngIf="!globals.hideHeaderFooter">    
  <div class="d-flex justify-content-evenly flex-wrap mt-3">          
    <a class="m-icon text-center" routerLink="/"><img loading="lazy" src="/assets/images/icons/menu/svg/home.svg" alt="IFA icon">Home</a>
    <a class="m-icon text-center" routerLink="/taxes"><img loading="lazy" src="/assets/images/icons/menu/svg/taxes.svg" alt="tax form icon">Taxes</a>
    <a class="m-icon text-center" routerLink="/about"><img loading="lazy" src="/assets/images/icons/menu/svg/about-us.svg" alt="people icon">About Us</a>
    <a class="m-icon text-center" href="tel:{{globals.phoneTollFree}}"><img loading="lazy" src="/assets/images/icons/menu/svg/phone.svg" alt="Call Us">{{globals.phoneTollFree}}</a>
    <a class="m-icon text-center" routerLink="/contact"><img loading="lazy" src="/assets/images/icons/menu/svg/contact-us.svg" alt="smart phone icon">Contact Us</a>
    <div class="m-icon text-center" (click)="newsletterSubscribeClick()"><img loading="lazy" src="/assets/images/icons/menu/svg/newsletter-subscribe.svg" alt="Newsletter Subscribe">Newsletter Subscribe</div>
    <a class="m-icon text-center" href="https://wealth.emaplan.com/ema/Default.aspx?ema/ria/ifa" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/emoney.svg" alt="eMoney logo icon">eMoney</a>
    <a class="m-icon text-center" routerLink="/app"><img loading="lazy" src="/assets/images/icons/menu/svg/ifa-app.svg" alt="app store icon">IFA Apps</a>
    <a class="m-icon text-center" href="{{globals.amazonIndexFundsTwelveStepKindleUrl}}" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/kindle.svg" alt="Kindle logo icon">Index Funds Book on Kindle</a>
    <a class="m-icon text-center" href="{{globals.amazonIndexFundsTwelveStepAudioBookUrl}}" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/index-funds-audiobooks.svg" alt="Audible logo icon">Index Funds Audiobook</a>
    <a class="m-icon text-center" routerLink="/careers"><img loading="lazy" src="/assets/images/icons/menu/svg/careers.svg" alt="business person icon">Careers</a>
    <a class="m-icon text-center" href="https://www.facebook.com/ifadotcom" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/facebook.svg" alt="Facebook logo icon">Facebook</a>
    <a class="m-icon text-center" href="https://twitter.com/ifadotcom" target="_blank"><img loading="lazy" src="/assets/svg/x.svg" alt="Twitter / X">X</a>
    
    <a class="m-icon text-center" href="https://www.linkedin.com/company/index-funds-advisors-inc-?trk=top_nav_home" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/linkedin.svg" alt="LinkedIn icon">LinkedIn</a>
    <a class="m-icon text-center" href="https://www.instagram.com/indexfundadvisors/" target="_blank"><img loading="lazy" src="/assets/images/icons/menu/svg/instagram.svg" alt="Instagram icon">Instagram</a>
    <a class="m-icon text-center" routerLink="/videos"><img loading="lazy" src="/assets/images/icons/menu/svg/videos.svg" alt="video playing icon ">Videos</a>
    <a class="m-icon text-center" href="https://www.youtube.com/user/IndexFundsAdvisors" target="_blank"><img loading="lazy" class="box-shadow" src="/assets/images/icons/menu/svg/youtube.svg" alt="Youtube icon">Youtube</a>
    <a class="m-icon text-center" routerLink="/glossary"><img loading="lazy" src="/assets/images/icons/menu/svg/glossary.svg" alt="Glossary icon">Glossary</a>
    <a class="m-icon text-center" routerLink="/disclosures/terms"><img loading="lazy" src="/assets/images/icons/menu/svg/terms.svg" alt="Terms document icon">Terms</a>
    <a class="m-icon text-center" routerLink="/disclosures"><img loading="lazy" src="/assets/images/icons/menu/svg/disclosures.svg" alt="legal paper icon">Disclosures</a>
    <a class="m-icon text-center" routerLink="/disclosures/index-data"><img loading="lazy" src="/assets/images/icons/menu/svg/index-descriptions.svg" alt="info icon">Index Descriptions</a>
    <a href="https://www.ifa.com/pdfs/ifa-form-crs.pdf" class="m-icon text-center" ><img height="40" width="40" src="/assets/images/icons/menu/svg/form-crs.svg" alt="form-crs icon">Form CRS</a>
  </div>


<div class="container p-4 disclosure" >
<div class="row d-flex">
  <div class="col">
    The data provided in charts referring to IFA Index Portfolios is hypothetical back-tested performance and is not actual client performance. Performance data for the IFA Index Portfolios is shown net of IFA's highest advisory fee and the underlying mutual fund expenses. IFA Indexes when shown individually do not reflect a deduction of advisory fees. None of the data reflects trading costs or taxes, which would have lowered performance by these costs. See more important disclosures at <a routerLink="/disclosures">ifabt.com.</a>
  </div>
  <div class="row">
    <div class="col copyright p-3">
    &copy; 1999-{{globals.currentYear}} Index Fund Advisors, Inc. All Rights Reserved | {{globals.mainAddress}} | <a class="font14" href="tel:{{globals.phoneTollFree}}">{{globals.phoneTollFree}}</a>
    </div>
  </div>
</div>
</div>
</div>

<div class="modal fade" data-bs-backdrop="static" tabindex="-1" id="modalNewsletterSubscribe">
<div class="modal-dialog modal-dialog-centered ">
<div class="modal-content">
  
  <div class="modal-body p-5">
    <button type="button" title="Close" class="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close" style="top:5px;right:5px"></button>
    <div *ngIf="emailSubscribed" class="p-5 text-center">You are now subscribed!</div>
    <form [formGroup]="subscribeForm" (ngSubmit)="submitEmailSubscribe()" *ngIf="!emailSubscribed">
      <p class="text-center">Subscribe to receive IFA newsletters<br>and timely industry insights.</p>
      <div class="form-group">
        <div class="col-md-10 offset-md-1">
          <input id="newsletter_subscribe_email" formControlName="email" type="email" class=" semi-trans form-control mb-3 p-3 height-35 font-w font-16 " placeholder="email">             
          <div *ngIf="subscribeForm.invalid && (subscribeForm.dirty || subscribeForm.touched)" class="pb-3 text-danger text-center">
            Please enter a valid email.
          </div>
        </div>
      </div>
      <button [disabled]="emailSubscribeSubmitting" id="emailSubscribeButton" style="max-width:200px; display:block; margin:0 auto" class=" text-center blue-button font-w  font-16 border-radius-none pad-8 font-open-l">Subscribe</button>
  </form>
  </div>
        <!--<div class="modal-footer">
   
<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  </div> -->
</div>
</div>
</div>