import {  Component,  OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject,  Subscription } from 'rxjs';
import { Globals } from '../globals';
import { Data2Service } from '../services/data2/data2.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  standalone:false
  // animations:[routeTransitionAnimations]

})
export class PagesComponent implements OnDestroy {
  title = 'Welcome to IFA.com';  
  loginDisplay = false;
  accounts:any = null;
  
  
  accountData ={
    accountIcon:"/assets/images/icons/menu/account.svg",
    accountIconText:"IFA.com Access",
    preferredUserName:""
  }
  dataSource: any =[];
  private readonly _destroying$ = new Subject<void>();
  subscriptions:Array<Subscription> = [];

  constructor( ) {}

  ngInit(){
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  
}
