import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';  
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { Globals } from 'src/app/globals';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { Data2Service } from 'src/app/services/data2/data2.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-navigation-footer',
  templateUrl: './navigation-footer.component.html',
  styleUrls: ['./navigation-footer.component.scss'],
  imports:[ReactiveFormsModule,FormsModule,CommonModule,RouterModule]
})
export class NavigationFooterComponent implements OnInit, AfterViewInit {
  bootstrap:any;
  subscribeModal : any|undefined;
  subscribeForm;
  
  emailSubscribeSubmitting = false;
  emailSubscribed = false;

  constructor( 
    public globals:Globals, 
    public bps:BreakpointService,
    private fb:FormBuilder,
    private data2Service:Data2Service,
    @Inject(PLATFORM_ID) private platformId: Object
    ) {
    this.subscribeForm = fb.group({    
      email: ['', Validators.email]
    });
   }
  async ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {  
      // Safe to use document here  
      this.bootstrap = await import('bootstrap');
      var element = document.getElementById("modalNewsletterSubscribe") as HTMLElement;
      this.subscribeModal = new this.bootstrap.Modal(element);
      // this.subscribeModal.show(); //For Debugging the Login Prompt        
    }
  }

  ngOnInit(): void {
  }
  newsletterSubscribeClick(){    
    this.subscribeModal?.show();
  }
  submitEmailSubscribe(){
    if(!this.emailSubscribeSubmitting && !this.subscribeForm.invalid){
      this.emailSubscribeSubmitting = true;    
      //console.log("submitEmailSubscribe",this.subscribeForm.value);
      if(this.subscribeForm.value.email!=null){
        this.data2Service.emailSubscribe(this.subscribeForm.value.email).subscribe(resp=>{
         // console.log("Subscribe to Email Response:",resp);
          setTimeout(()=>{
            this.subscribeModal?.hide();
          },3000)
       
          this.emailSubscribed = true;
          this.emailSubscribeSubmitting = false;
        })
      }
     
    }
 
  }
}
