
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import { PagesNavigationalRoutes } from './pages.routing.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelope, faEye, faLock, faPhone, faPhoneAlt, faSearch, faLink, faStar as fasStar, faGripHorizontal, faShieldAlt, faInfoCircle, faCalculator, faPencilAlt, faTools, faUsers, faChevronRight, faIdCard, faTags, faSignInAlt, faChevronCircleRight, faChevronCircleLeft, faReplyAll, faCamera, faVideo, faPlay, faGlobeAmericas, faNewspaper, faDollarSign, faDownload, faTrash, faThumbsUp, faThumbsDown, faEdit, faFilter, faSquare, faMapMarker, faFilePdf, faFileAlt, faClock, faUser, faBars, faChevronLeft, faTimes, faChartBar, faComment, faColumns, faBookmark, faBook, faPrint, faCog, faLocationArrow, faLockOpen, faPlusCircle, faMusic } from '@fortawesome/free-solid-svg-icons';

//import { faInfoCircle as farInfoCircle } from '@fortawesome/free-regular-svg-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../services/analytics.service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from './_components/navigation/navigation.component';
import { NavigationFooterComponent } from './_components/navigation-footer/navigation-footer.component';
import { GetTheAppToastComponent } from './_components/get-the-app-toast/get-the-app-toast.component';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha-2';

// import { NavigationFooterComponent } from './_components/navigation-footer/navigation-footer.component';
// import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha-2';



export const options: Partial<null> | (() => Partial<any>) = null;

@NgModule({
  declarations: [
    PagesComponent, 
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,    
    PagesNavigationalRoutes,
    ReactiveFormsModule,
    RouterOutlet,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    NavigationComponent,
    NavigationFooterComponent,
    GetTheAppToastComponent
],
  providers:[
    AnalyticsService,
     {
       provide: RECAPTCHA_SETTINGS,
       useValue: {
         siteKey: environment.googleReCaptchaKey
       } as RecaptchaSettings
     },
    provideEnvironmentNgxMask()
  ]
})
export class PagesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faBars,
      faBook,
      faBookmark,
      faCalculator,
      faCamera,
      faChartBar,
      faChevronCircleLeft,
      faChevronCircleRight,
      faChevronLeft,
      faChevronRight,
      faClock,
      faCog,
      faComment,
      faDollarSign,
      faDownload,
      faEdit,
      faEnvelope,
      faEye,
      faFilePdf,
      faFilter,
      faGlobeAmericas,
      faGripHorizontal,
      
      faIdCard,
      faInfoCircle,
      faLink,
      faLocationArrow,
      faLock,
      faLockOpen,
      faMapMarker, 
      faMusic,
      faNewspaper,
      faPencilAlt,
      faPhoneAlt,
      faPlay,
      faPlusCircle,
      faPrint,
      faReplyAll,
      faSearch,
      faShieldAlt,
      faSignInAlt,
      faSquare,
    
      faTags,
      faTimes,
      faThumbsDown,
      faThumbsUp,
      faTools,
      faTrash,
      faUsers,
      faVideo,
      faFileAlt,
      faClock,
      faUser
    );
  }
  
 }

