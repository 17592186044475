<div class="getTheAppToast" *ngIf="!closed">
    <fa-icon [icon]="['fas','times']" style="color:#fff; float:right; margin:2px; margin-right:8px;padding:2px;font-size:20px;" (click)="close()"></fa-icon>

    <div class="d-flex flex-row justify-content-between mx-2">
        <div class="d-flex flex-row justify-content-center">
            <div class="appLogo my-3 mx-1">
                <div>IFA</div>
            </div>
            <div class="my-3 text-light ms-1"><span class="f-18">Index Fund Advisors</span><br>
                <span class="no-small">Wealth Management &bull; Taxes</span></div>
        </div>

        <a class="btn btn-primary btn-lg my-3 me-2" href="{{link}}" target="_blank">Get the app</a>
    </div>


</div>