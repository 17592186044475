import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Inject, PLATFORM_ID, AfterViewInit, ViewChild, ElementRef, afterNextRender } from '@angular/core';
//import { Dropdown, Offcanvas } from 'bootstrap';
import { Router, RouterModule } from '@angular/router';
import { catchError, Subscription } from 'rxjs';
import { Globals } from 'src/app/globals';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ConsentService } from 'src/app/services/consent.service';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';  
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports:[CommonModule,RouterModule,FontAwesomeModule]
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit{

  @ViewChild('searchTerm', {static:false}) searchTerm!:ElementRef;
  @ViewChild('offcanvasRight', {static:false}) offcanvasRight!:ElementRef;
  @Input() accountData:any = {};  
  @Input() loginDisplay = false;
  @Output() login = new EventEmitter;
  @Output() logout = new EventEmitter;
  offcanvas:any|undefined;
  subscriptions: Array<Subscription> = [];
  vIC=false;
  
  mainMenuMobileActiveId:string = 'home';
  bootstrap:any;

  constructor( 
    private router: Router, 
    public globals:Globals, 
    public bps:BreakpointService, 
    public consentService:ConsentService, 
    @Inject(PLATFORM_ID) private platformId: Object,
    // @Inject(DOCUMENT) private _document: Document,
  ) { 
   //console.log("navigation constructor")

    this.subscriptions.push(bps.breakpointUpdate.subscribe(resp=>{
      //console.log("navigation component > bps update > ",resp);
      if(resp==false){
        this.disposeOffCanvas();
      }
      else{
        this.initOffCanvas();
      }
    }))

   
  }
//   ngOnChanges(changes: SimpleChanges): void {
//     if(changes['loginDisplay'].currentValue){
//       setTimeout(()=>{
// //        console.log("Navigation Component Changes",changes);
//         this.InitializeDropDown();
//       },1000);
//     }
 
//   }
  ngOnDestroy(): void {
    this.subscriptions.forEach(x=>x.unsubscribe());
  }

  ngOnInit(): void {   
  }
  _login(){
    this.login.emit();
  }
  _logout(){
    this.logout.emit();
    this.loginDisplay=false;
  }

  async ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {  
      this.bootstrap = await import("bootstrap");     
      setTimeout(()=>{this.vIC = true;},1000);
    }
 }

 branchClick(evt:any){
  //console.log("branchClick",evt.target);
  var branch = evt.target.closest(".branch");
  branch.classList.add("branchClicked");
  setTimeout(()=>{
      branch.classList.remove("branchClicked");
  },2000)
 }



 
  submenu(id:any,direction:string=""){   
    
   //console.log(id,direction)
    if(direction==".."){
//      console.log(id.srcElement)
      id.srcElement?.closest(".hasSub").classList.toggle("active");
    } 
    else{
//     console.log(id.currentTarget)
     id.target?.classList.toggle("active");
    }
    id.stopPropagation();
    id.preventDefault();
  
    return false; //stopPropagation

      // document.getElementById(this.mainMenuMobileActiveId)?.classList.toggle("active");
      // this.mainMenuMobileActiveId=id;
      // document.getElementById(this.mainMenuMobileActiveId)?.classList.toggle("active");      
  }

  mobileMenuClick(event:any){
    //console.log("mobileMenuClick",event,event.target?.attributes, event.target?.className);

    var className = event.target?.className;
//    console.log("className",className)
    if(className && className.indexOf && className.indexOf("hasSub")>-1){     
      event.target?.classList.toggle("active");
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    else if(className && className.indexOf &&  className.indexOf("btn-success")>-1){
      event.srcElement?.closest(".hasSub").classList.toggle("active");
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    

    var attrs = event.target?.attributes;
    if(!attrs || attrs.length<1){
      console.log("attrs",attrs)
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    var attributes = [];
    for (var i=0;i<attrs.length;i++){
      var att = attrs[i];
      attributes.push(att.nodeName);
  }
//    console.log("attributes",attributes,attributes.indexOf("routerlink"))
    if(attributes && attributes.length > 0){
      if(attributes.indexOf("routerlink")>0 || attributes.indexOf("href")>0){
        this.closeOffCanvas();
      }
      else{
        var hasSub = event.srcElement?.closest(".hasSub");
//        console.log("hasSub",hasSub)
        if(hasSub){
          hasSub.classList.toggle("active");
        }
      }     
      //this.offcanvas.dispose();
    }

    var localURL = true;

    if(event.target && event.target.attributes){   
      if(event.target.attributes.href && event.target.attributes.target){
        localURL=false
      }

    }

    if(localURL){
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    else{
      return true;
    }
  }

  searchIFA() {
    const searchTermValue = this.searchTerm.nativeElement.value; 
    //console.log("searchIFA",searchTermValue)
    if(searchTermValue.length>1){
      this.router.navigate(['/search'], { queryParams: { q: searchTermValue } });
    }
  }

  async openOffCanvas(){
    this.initOffCanvas();
    this.offcanvas?.show();
  }
  async closeOffCanvas(){
    this.initOffCanvas();
    this.offcanvas?.hide();
  }
  initOffCanvas(){
    if(!this.offcanvas){  
      // if (isPlatformBrowser(this.platformId)) {  
      //   // Safe to use document here      
      //   //var el = document.getElementById("offcanvasRight") as HTMLElement;       
      // }
      var el = this.offcanvasRight.nativeElement;
      if(el){
        this.offcanvas = new this.bootstrap.Offcanvas(el);        
      }
    }
  }
  disposeOffCanvas(){
      if(this.offcanvas){        
        this.offcanvas.dispose();
        this.offcanvas=undefined;
      }    
  }

  _clearStorage(){
    if (isPlatformBrowser(this.platformId)) {  
      // Safe to use document here  
      document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
      window.localStorage.clear();
      window.sessionStorage.clear();   
    }
  }

}
