import { hostnameGuard } from '../guards/hostname.guard';
import { MasterGuard } from '../guards/master.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagesComponent } from './pages.component';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';
import { NoPrerenderGuard } from '../guards/no-prerender.guard';




const routes: Routes = [ 
  {
    path: '',
    component: PagesComponent,
    canActivateChild: [MasterGuard],
    canLoad:[MasterGuard],
    children: [   
    
      { path:   '',  loadComponent:()=> import("./home/home.component").then(c=>c.HomeComponent)},
      {
        path:"404",    
        component:NotFoundComponent
      },


  //     //REDIRECCTS HERE
      { path: 'galton-board', redirectTo: '/galtonboard', pathMatch: 'full' },
      { path: 'gp', redirectTo: '/glossary#glide-path', pathMatch: 'full' },
      { path: 'ra', redirectTo: '/retirement-analyzer', pathMatch: 'full' },
      { path: 'rb', redirectTo: '/articles/rebalancing_portfolios', pathMatch: 'full' },
      { path: 'tlh', redirectTo: '/articles/market_volatility_opens_loss_harvesting_opportunities', pathMatch: 'full' },
      { path: 'videos/index-funds-the-movie', redirectTo: '/indexfundsthemovie', pathMatch: 'full' },
      { path: 'videos/index_funds_the_movie', redirectTo: '/indexfundsthemovie', pathMatch: 'full' },
      { path: 'videos/index-funds-the-movie-2024-edition', redirectTo: '/indexfundsthemovie', pathMatch: 'full' },
      { path: 'videos/index_funds_the_movie_2024_edition', redirectTo: '/indexfundsthemovie', pathMatch: 'full' },
      { path: 'videos/tune-out-the-noise-movie-page', redirectTo: '/tune-out-the-noise', pathMatch: 'full' },
      { path: 'videos/tune_out_the_noise_movie_page', redirectTo: '/tune-out-the-noise', pathMatch: 'full' },
      { path: 'network-members', redirectTo: '/promoters', pathMatch: 'full' },
      { path: 'mark-higgins', redirectTo: '/institutional', pathMatch: 'full' },
      { path: 'brad', redirectTo: '/about/advisors', pathMatch: 'full' },
      { path: 'becky', redirectTo: '/about/advisors', pathMatch: 'full' },
      { path: 'tyler', redirectTo: '/about/advisors', pathMatch: 'full' },
      { path: 'about/financial-planning-team', redirectTo: '/about/financial-planning-department', pathMatch: 'full' },
      { path: 'faqs/three-arch-wealth', redirectTo: '/faqs/index-fund-advisors', pathMatch: 'full' },
      
    

  //     //ADVISOR LANDING PAGES HERE
      { path:   'mark',    loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),  data:{first:"mark", last:"hebner"}},  
      { path:   'wes',     loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),  data:{first:"wes", last:"long"}},    
      { path:   'denise',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"denise", last:"delaney"}},
      { path:   'mary',    loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"mary", last:"brunson"}},
      { path:   'derick',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"derick", last:"kann"}},
      { path:   'venkat',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"venkat", last:"yarlagadda"}},
      { path:   'gordon',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"gordon", last:"shuler"}},
      { path:   'neal',    loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"neal", last:"rutter"}},      
      { path:   'daniel',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"daniel", last:"chong"}},
      { path:   'kerrie',  loadChildren: () => import('../pages/about/advisor-details/advisor-details.module').then(m => m.AdvisorDetailsModule),   data:{first:"kerrie", last:"lloyd"}},
      //redirected to institutional? { path:   'mark-higgins',       pathMatch:'full', component:  AdvisorDetailsComponent, data:{first:"mark", last:"higgins"}},
  //     //END ADVISOR LANDING PAGES
      
      
        { path:   'about',                loadChildren: () => import('../pages/about/about.module').then(m => m.AboutModule)},      
        { path:   'academic-papers',      pathMatch:'full', loadChildren: () => import('../pages/academic-papers/academic-papers.module').then(m => m.AcademicPapersModule)},
        { path:   'alpha',                loadChildren: () => import('../pages/alpha/alpha.module').then(m => m.AlphaModule)},
        { path:   'app',                  loadChildren: () => import('./app/mobile-app.module').then(m => m.MobileAppModule)},
        { path:   'articles',             loadChildren: () => import('../pages/articles/articles.module').then(m => m.ArticlesModule)},
        { path:   'awards',               pathMatch:'full', loadComponent: () => import('./awards/awards.component').then(c =>c.AwardsComponent) },
        { path:   'become-client',        loadChildren:()=>import('../pages/become-client/become-client.module').then(m=>m.BecomeClientModule)},
        { path:   'books',                loadComponent:()=>import('../pages/books/books.component').then(c=>c.BooksComponent)},
        { path:   'book-library',         loadChildren:()=>import('../pages/book-library/book-library.module').then(m=>m.BookLibraryModule)},
        { path:   'calculator',           pathMatch:'full', loadChildren: () => import('../pages/calculator/calculator.module').then(m => m.CalculatorModule)},
  //       //{ path:   'calculator',           pathMatch: 'full',  redirectTo: 'undergoing-maintenance' },
        { path:   'calculators/retirement-income',          loadChildren: () => import('../pages/calculators/retirement-income/retirement-income.module').then(m => m.RetirementIncomeModule)},
        { path:   'calculators/target-date-comparison',    loadChildren: () => import('../pages/calculators/target-date-comparison/target-date-comparison.module').then(m => m.TargetDateComparisonModule)},
        { path:   'calculators/tax-return-fee-calculator',  loadComponent: () => import('../pages/calculators/tax-fee-estimation/tax-return-fee-calculator.component').then(c => c.TaxReturnFeeCalculatorComponent)},
        { path:   'calculators',          loadChildren: () => import('../pages/calculators/calculators.module').then(m => m.CalculatorsModule)},
        { path:   'careers',              pathMatch:'full', loadChildren: () => import('../pages/careers/careers.module').then(m => m.CareersModule)},
        { path:   'charts',               loadChildren: () => import('../pages/charts/charts.module').then(m => m.ChartsModule)},
        { path:   'college-savings',      loadChildren:()=>import('../pages/college-savings/college-savings.module').then(m=>m.CollegeSavingsModule)},  
        { path:   'contact',              pathMatch:'full', loadChildren: () => import('../pages/contact/contact.module').then(m => m.ContactModule)},
      
        { path:   'disclosures',          loadChildren: () => import('../pages/disclosures/disclosures.module').then(m => m.DisclosuresModule)},
        { path:   'erisa-338-fiduciary-advisors-and-services',      pathMatch:'full', loadComponent: ()=> import('./landing-pages/erisa-338-fiduciary-advisors-and-services/erisa/erisa.component').then(c=>c.ErisaComponent)},
        { path:   'estate-plan-analysis', pathMatch:'full', loadComponent: ()=> import('./estate-plan-analysis/estate-plan-analysis.component').then(c=>c.EstatePlanAnalysisComponent)},
        { path:   'estate-planning',      pathMatch:'full', loadComponent: ()=> import('./estate-planning/estate-planning.component').then(c=>c.EstatePlanningComponent)},
        { path:   'eugene-fama',         loadComponent: () => import('../pages/landing-pages/eugene-fama/eugene-fama.component').then(c => c.EugeneFamaComponent)},
        //{ path:   'eventregistration-venkat202309',          loadComponent: () => import('./event/venkat2309/venkat2309.component').then(ev => ev.Venkat2309Component) },
        //{ path:   'eventregistration-higgins202311',         loadComponent: () => import('./event/higgins2311/higgins2311.component').then(ev => ev.Higgins2311Component) },
        { path:   'external.aspx',             pathMatch:'full', loadChildren:() => import('../pages/external/external.module').then(m=>m.ExternalModule)},  
        
        { path:   'faqs',                 loadChildren:() => import('../pages/faqs/faqs.module').then(m=>m.FaqsModule)}, 
        { path:   'fee-calculator',       pathMatch:'full', loadComponent: () => import('./fee-calculator/fee-calculator.component').then(c =>c.FeeCalculatorComponent) },
        { path:   'fees',                 pathMatch:'full', loadChildren:() => import('../pages/fees/fees.module').then(m=>m.FeesModule)}, 

        //{ path:   'frameworks/bootstrap/dropdown',            pathMatch:'full', component:  DropdownComponent },
        { path:   'fiduciary-investments-solutions-advisors-and-management-services',    loadComponent: () => import('../pages/landing-pages/fiduciary-investments-solutions-advisors-and-management-services/fiduciary/fiduciary.component').then(c => c.FiduciaryComponent)},
        { path:   'financial-wealth-services-engineers',      loadComponent: () => import('../pages/landing-pages/financial-wealth-services-engineers/financial-wealth-services-engineers.component').then(c => c.FinancialWealthServicesEngineersComponent)},
        { path:   'find-an-advisor',      pathMatch:'full', loadChildren: () => import('../pages/find-an-advisor/find-an-advisor.module').then(m => m.FindAnAdvisorModule)},
        { path:   'gallery',              pathMatch:'full', loadComponent:()=>import('./gallery/gallery.component').then(c=>c.GalleryComponent)},
        { path:   'gallery-all',          pathMatch:'full', loadComponent:()=>import('./gallery-all/gallery-all.component').then(c=>c.GalleryAllComponent)},
        { path:   'galtonboard',         loadChildren: () => import('./galton-board/galton-board.module').then(m => m.GaltonBoardModule)},
        { path:   'glossary',             pathMatch:'full', loadComponent: () => import('./glossary/glossary.component').then(c =>c.GlossaryComponent) },
        { path:   'harry-markowitz',       loadComponent: () => import('./landing-pages/harry-markowitz/harry-markowitz.component').then(c => c.HarryMarkowitzComponent)},
        { path:   'indexfundsthemovie',    loadComponent: () => import('./indexfundsthemovie/indexfundsthemovie.component').then(c => c.IndexfundsthemovieComponent)},
        { path:   'institutional',        pathMatch:'full', loadComponent: () => import('./institutional/institutional.component').then(c =>c.InstitutionalComponent) },
        { path:   'investingtheevidence', loadChildren: () => import('../pages/investingtheevidence/investingtheevidence.module').then(m => m.InvestingtheevidenceModule)},
        { path:   'links',                pathMatch:'full', loadComponent: ()=> import('./links/links.component').then(c=>c.LinksComponent) },
        { path:   'login',                pathMatch:'full', loadComponent: ()=> import('./login/login.component').then(c=>c.LoginComponent)},
  //    //{ path:   'morningstar-widget',      loadChildren:()=> import('../pages/_components/widgets/morningstar-total-and-price-returns/morningstar-total-and-price-returns.module').then(m=>m.MorningstarTotalAndPriceReturnsModule)},
  //    redirected // { path:   'network-members',      loadChildren:()=> import('../pages/network-members/network-members.module').then(m=>m.NetworkMembersModule)},
        { path:   'notice-of-incident',   pathMatch:'full', loadChildren:()=> import('../pages/landing-pages/notice-of-incident/notice-of-incident.module').then(m=>m.NoticeOfIncidentModule)},
        { path:   'open-account',         loadChildren:()=> import('../pages/open-account/open-account.module').then(m=>m.OpenAccountModule)},
        { path:   'petition',            loadChildren:()=> import('../pages/petitions/petitions.module').then(m=>m.PetitionsModule)},
        { path:   'petitions',            loadChildren:()=> import('../pages/petitions/petitions.module').then(m=>m.PetitionsModule)},
        { path:   'plates', 
                  children:
                  [
                    { path: '',           pathMatch:'full', loadComponent: () => import('./plates/plates.component').then(c =>c.PlatesComponent) },
                    { path: ':id',        pathMatch:'full', loadComponent: () => import('./plates/plate/plate.component').then(c =>c.PlateComponent) },
                  ]
                },
      { path:   'portfolios',           loadChildren:()=> import('../pages/portfolios/portfolios.module').then(m=>m.PortfoliosModule)},      
  //     //{ path:   'pdfs',             loadChildren:()=> import('../pages/pdfs/pdfs.module').then(m=>m.PdfsModule)},
      { path:   'promoters',      loadChildren:()=> import('../pages/network-members/network-members.module').then(m=>m.NetworkMembersModule)},
      { path:   'quotes',               pathMatch:'full', loadComponent: () => import('./quotes/quotes.component').then(c =>c.QuotesComponent) },
      { path:   'quotes/:author',       pathMatch:'full', loadComponent: () => import('./quotes/quotes-by-author/quotes-by-author.component').then(c =>c.QuotesByAuthorComponent) },
      { path:   'required-consent',     pathMatch:'full', loadChildren: () => import('../pages/required-consent/required-consent.module').then(m => m.RequiredConsentModule)}, 
      { path:   'retirement-analyzer',      loadChildren:()=>import('../pages/retirement-analyzer/retirement-analyzer.module').then(m=>m.RetirementAnalyzerModule)},  
      { path:   'retirement-plans',     loadChildren: () => import('../pages/retirement-plans/retirement-plans.module').then(m => m.RetirementPlansModule)},
      { path:   'roku',                 pathMatch:'full', loadComponent: () => import('./roku/roku.component').then(c =>c.RokuComponent) },
      { path:   'search',               pathMatch:'full', loadComponent: () => import('./search/search.component').then(c =>c.SearchComponent) },
      { path:   'services',             loadComponent: () => import('./services/services.component').then(c => c.ServicesComponent)},
      { path:   'sitemap',              pathMatch:'full', loadComponent: () => import('./sitemap/sitemap.component').then(c =>c.SitemapComponent)  },
      { path:   'speculation-blues',    pathMatch:'full', loadComponent: () => import('./speculation-blues/speculation-blues.component').then(c => c.SpeculationBluesComponent)},
      { path:   'survey/profile/result',pathMatch:'full', loadChildren: () => import('../pages/survey/survey-results/survey-results.module').then(m => m.SurveyResultsModule)},
      { path:   'sustainability',       pathMatch:'full', loadComponent: () => import('./sustainability/sustainability.component').then(c =>c.SustainabilityComponent) },
      { path:   'schwab',               pathMatch:'full', loadComponent: () => import('./schwab/schwab.component').then(c =>c.SchwabComponent) },
      { path:   'store',                 loadComponent:()=> import('./store/store.component').then(c=>c.StoreComponent)},
      
      { path:   'tags',                 loadComponent: ()=>import('./tags/tags.component').then(c=>c.TagsComponent)},
  //    // { path:   't1040',          loadComponent: () => import('./t1040/t1040.component').then(t => t.T1040Component) },
      { path:   'taxes',                loadChildren:()=>import('../pages/taxes/taxes.module').then(m=>m.TaxesModule)},
      
      { path:   'tax-forms',    loadChildren:()=>import('../pages/tax-forms/tax-forms.module').then(m=>m.TaxFormsModule)},
      { path:   't-stat-calculator',    pathMatch:'full', loadComponent: ()=> import('./t-stat-calculator/t-stat-calculator.component').then(c=>c.TStatCalculatorComponent)}, 
      { path:   'tune-out-the-noise', loadChildren: () => import('../pages/tune-out-the-noise/tune-out-the-noise.module').then(m => m.TuneOutTheNoiseModule)},
      { path:   '12steps', loadChildren: () => import('../pages/twelve-steps/twelve-steps.module').then(m => m.TwelveStepsModule)},
      { path:   'undergoing-maintenance',                 loadComponent: ()=> import('./undergoing-maintenance/undergoing-maintenance.component').then(c=>c.UndergoingMaintenanceComponent)},
      
      { path:   'videos',               loadChildren:() => import ('../pages/videos/videos.module').then(m=>m.VideosModule)},      
      { path:   'whats_new',            pathMatch:'full', loadComponent: ()=>import('./whats-new/whats-new.component').then(c=>c.WhatsNewComponent)},

      {
        path:'**',
        redirectTo:'404',
        //canActivate:[NoPrerenderGuard]
      }      
    ]
  },
  
  
 
];



export const PagesNavigationalRoutes = RouterModule.forChild(routes);
